import React, { createContext, useState } from 'react'

export interface UsedNumberType {
    number: number
    selected: boolean
    is_verified: boolean
}
export type UsedItemType = Record<number, UsedNumberType>
export interface FormContextType {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    usedNumbers: UsedItemType
    setUsedNumbers: React.Dispatch<React.SetStateAction<UsedItemType>>
    nameValue: string
    setNameValue: React.Dispatch<React.SetStateAction<string>>
    phoneValue: string
    setPhoneValue: React.Dispatch<React.SetStateAction<string>>
    submitSuccess: boolean
    setSubmitSuccess: React.Dispatch<React.SetStateAction<boolean>>
    submitError: undefined | string
    setSubmitError: React.Dispatch<React.SetStateAction<undefined | string>>
}
export const defaultFormContext = {
    showModal: false,
    usedNumbers: {}
}

export const FormContext = createContext<FormContextType>(defaultFormContext as FormContextType)

export const FormContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [showModal, setShowModal] = useState(false)
    const [usedNumbers, setUsedNumbers] = useState<UsedItemType>({} satisfies UsedItemType)
    const [nameValue, setNameValue] = useState('')
    const [phoneValue, setPhoneValue] = useState('')
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [submitError, setSubmitError] = useState<undefined | string>()

    return (
        <FormContext.Provider value={{
            showModal,
            setShowModal,
            usedNumbers,
            setUsedNumbers,
            nameValue,
            setNameValue,
            phoneValue,
            setPhoneValue,
            submitSuccess,
            setSubmitSuccess,
            submitError,
            setSubmitError
        }}>
            {children}
        </FormContext.Provider>
    )
}
