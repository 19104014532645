import axios from '../../api'

export interface EntryData {
    name: string
    numbers: number[]
    telephone_number: string
}

export const getEntryNumbers = async () => {
    const response = await axios.get('/numbers')
    return response.data
}

export const createEntryNumbers = async (data: EntryData) => {
    const response = await axios.post('/numbers', data)
    return response.data
}
