import styled from '@emotion/styled'

import { Header } from '../components/header/header'
import { Carousel } from '../components/carousel/carousel'
import { Container } from '../components/container/container'
import { ContainerForCarousel } from '../features/entry_form/container_carousel'
import { Sponsors } from '../features/sponsors/sponsors'
import { Footer } from '../components/footer/footer'

const FormCarousel = styled.div`
  height: calc(100vh - 140px);
`

export const MainPage = () => {
    return (
        <Container>
            <Header/>
            <FormCarousel className='relative'>
                <Carousel/>
                <ContainerForCarousel/>
            </FormCarousel>
            <Sponsors/>
            <Footer/>
        </Container>
    )
}
