import styled from '@emotion/styled'
import SponsorList from './sponsors_list.json'

const StyledContainer = styled.div`
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

const OursTextStyle = styled.div`
  font-size: 18px;
  color: #253d60;
`
const SponsorsTextStyle = styled.div`
  font-size: 36px;
`

const StyledHr = styled.hr`
  width: 10%;
  color: black;
  border-top: 3px solid black;
`

const SponsorsContainer = styled.div`
`

const SponsorItem = styled.div`
  border-radius: 5px;
`

const SponsorLogoContainer = styled.div`
  min-height: 320px;
`

const SponsorDescriptionContainer = styled.div`
    align-self: flex-end;  
`

const SponsorLogo = styled.img`
    max-width: 320px;
    max-height: 320px;
    margin: 0 auto;
`

export const Sponsors = () => {
    return (
        <StyledContainer className='container mx-auto px-4'>
            <TitleContainer>
                <OursTextStyle>Nuestros</OursTextStyle>
                <SponsorsTextStyle>Patrocinadores</SponsorsTextStyle>
                <StyledHr/>
            </TitleContainer>
            <SponsorsContainer className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 lg:gap-8'>
                {SponsorList.map((sponsor, index) => {
                    return (
                        <SponsorItem key={index}>
                            <SponsorLogoContainer className='flex justify-center align-middle'>
                                <SponsorLogo className='self-center' src={sponsor.logo} alt={sponsor.name} />
                            </SponsorLogoContainer>
                            <SponsorDescriptionContainer className='my-4'>
                                <strong>{sponsor.name}</strong>
                                <p>{sponsor.prize}</p>
                            </SponsorDescriptionContainer>
                        </SponsorItem>
                    )
                })}
            </SponsorsContainer>
        </StyledContainer>
    )
}
