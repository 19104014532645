import styled from '@emotion/styled'
import Logo from '../../shared/logo/white_logo.png'

const HeaderContainer = styled.div`
    background-color: #337ab0;
`

const LogoImage = styled.img`
    height: auto;
    width: 260px;
`

export const Header = () => {
    return (
        <HeaderContainer className='flex flex-col py-7 px-3 items-center'>
            <LogoImage src={Logo} alt="Salvando Lomitos y Michis Logo"/>
        </HeaderContainer>
    )
}
