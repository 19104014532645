import styled from '@emotion/styled'
import React from 'react'

const StyledContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 100%;
`

interface Props {
    children: any
}

export const Container: React.FC<Props> = (props) => {
    return (
        <StyledContainer>
            {props.children}
        </StyledContainer>
    )
}
