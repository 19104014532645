import styled from '@emotion/styled'
import { Form } from './form'
import { FormContextProvider } from './context'
import { Contact } from '../../components/contact/contact'
import { SelectorModal } from './selector_modal'

const FormContainer = styled.div`
  position: relative;
  background-color: rgba(230, 227, 221, 0.8);
  border-radius: 5px;
`

const StyledHr = styled.hr`
    width: 85%;
    color: black;
    border-top: 1px solid black;
    margin: 0 auto;
`

export const ContainerForCarousel = () => {
    return (
        <FormContextProvider>
            <SelectorModal onAccept={(() => {})}/>
            <div className='flex flex-col items-center justify-center content-center h-full'>
                <FormContainer>
                    <Form />
                    <StyledHr />
                    <Contact />
                </FormContainer>
            </div>
        </FormContextProvider>
    )
}
