import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'animate.css'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@emotion/react'
import MAIN_THEME from './theme'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
        <ThemeProvider theme={MAIN_THEME}>
            <RouterProvider router={router} />
        </ThemeProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
